import React from "react"
import { PageProps } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"
import classNames from "classnames"
import Layout from "../../components/Layout/Layout"
import SEO from "../../components/seo"
import componentStyles from "./b2-connect.module.scss"
import SolutionDescriptoionImg from "../../assets/images/projects/b2-connect-solution-description.png"
import TechnologyBackgroundImg from "../../assets/images/projects/b2-connect-technology-background.png"
import SmallProjectCard from "../../components/SmallProjectCard/SmallProjectCard"
import { findIndex, projectsList } from "../../hooks"
import {
  getBigThumbnailByLink,
  useBigProjectsCardsThumbnails,
} from "../../hooks/useBigPreviewProjectsCardsThumbnails"
import projectsStyles from "../projects.module.scss"
import LearnMoreSection from "../../components/LearnMoreSection/LearnMoreSection"

const challengeItems = [
  "projects.b2-connect.challenge.paragraph-2-list.item-1",
  "projects.b2-connect.challenge.paragraph-2-list.item-2",
  "projects.b2-connect.challenge.paragraph-2-list.item-3",
  "projects.b2-connect.challenge.paragraph-2-list.item-4",
  "projects.b2-connect.challenge.paragraph-2-list.item-5",
  "projects.b2-connect.challenge.paragraph-2-list.item-6",
]
const resultsItems = [
  "projects.b2-connect.results.paragraph-1-list.item-1",
  "projects.b2-connect.results.paragraph-1-list.item-2",
  "projects.b2-connect.results.paragraph-1-list.item-3",
  "projects.b2-connect.results.paragraph-1-list.item-4",
]

const B2Connect: React.FC<PageProps> = ({ location }) => {
  const intl = useIntl()
  const projects = projectsList
  const projectsThumbnails = useBigProjectsCardsThumbnails()

  const strongTextFormatter = text => (<b className="highlighted-text">{text}</b>)
  const bannerClasses = classNames(
    componentStyles.banner,
    componentStyles.ltrDirection,
  )
  const bannerContainerClasses = classNames(
    componentStyles.bannerContainer,
    "container",
  )
  const challengeClasses = classNames(
    componentStyles.challenge,
    "container",
    componentStyles.ltrDirection
  )
  const challengeListTitleClasses = classNames(
    componentStyles.challengeText,
    componentStyles.challengeListTitle,
  )
  const challengeTitleClasses = classNames(
    componentStyles.challengeTitle,
    "large-section-title"
  )
  const challengeListClasses = classNames(
    componentStyles.challengeTilesList,
    "grid-3"
  )
  const clientCompanyClasses = classNames(
    componentStyles.clientCompany,
    "container",
    componentStyles.ltrDirection,
  )
  const solutionDescriptionClasses = classNames(
    componentStyles.solutionDescription,
    "container",
    componentStyles.ltrDirection,
  )
  const solutionDescriptionTitleClasses = classNames(
    componentStyles.solutionDescriptionTitle,
    "large-section-title"
  )
  const resultsClasses = classNames(
    componentStyles.results,
    "container",
    componentStyles.ltrDirection,
  )
  const resultsTitleClasses = classNames(
    componentStyles.resultsTitle,
    "large-section-title"
  )
  const resultsListClasses = classNames(componentStyles.resultsCubeList, "grid-2")
  const technologyBackgroundClasses = classNames(
    componentStyles.technologyBackground,
    "container",
    componentStyles.ltrDirection,
  )
  const technologyBackgroundTitleClasses = classNames(
    componentStyles.technologyBackgroundTitle,
    "large-section-title"
  )
  const projectsTitleClasses = classNames("large-section-title")
  const blockPreview = classNames(projectsStyles.blockPreview, "container")
  const pathname = location.pathname
  const projectUrl = pathname.split("/")[3]
  const projectIndex = findIndex(projectUrl)

  return (
    <Layout location={location}>
      <SEO
        language={intl.locale}
        description={intl.formatMessage({
          id: "projects.b2-connect.seo.description",
        })}
        title={intl.formatMessage({ id: "projects.b2-connect.seo.title" })}
        pathname={location.pathname}
      />
      <section className={bannerClasses}>
        <div className={bannerContainerClasses}>
          <div className={componentStyles.bannerWrapper}>
            <h1 className={componentStyles.bannerTitle}>
              {intl.formatMessage({
                id: "projects.b2-connect.banner.title",
              })}
            </h1>
          </div>
        </div>
      </section>
      <section className={clientCompanyClasses}>
        <h2 className={componentStyles.clientCompanyTitle}>
          {intl.formatMessage({
            id: "projects.b2-connect.client-company.title",
          })}
        </h2>
        <p className={componentStyles.clientCompanyText}>
          <a href="https://www.b2connect.me/" className={componentStyles.clientCompanyLink} target="_blank">
            {intl.formatMessage({
              id: "projects.b2-connect.client-company.link-text",
            })}
          </a>
          {intl.formatMessage({
            id: "projects.b2-connect.client-company.text",
          })}
        </p>
      </section>
      <section className={challengeClasses}>
        <div className={componentStyles.challengeTextBlock}>
          <h2 className={challengeTitleClasses}>
            {intl.formatMessage({
              id: "projects.b2-connect.challenge.title",
            })}
          </h2>
          <p className={componentStyles.challengeText}>
            {intl.formatMessage({
              id: "projects.b2-connect.challenge.paragraph-1",
            })}
          </p>
          <p className={challengeListTitleClasses}>
            {intl.formatMessage({
              id: "projects.b2-connect.challenge.paragraph-2",
            })}
          </p>
        </div>
        <ul className={challengeListClasses}>
          {challengeItems.map((text) => (
            <li
              key={text}
              className={componentStyles.challengeTilesItem}
            >
              {intl.formatMessage({
                id: text,
              })}
            </li>
          ))}
        </ul>
      </section>
      <section className={solutionDescriptionClasses}>
        <div className={componentStyles.solutionDescriptionTextBlock}>
          <h2 className={solutionDescriptionTitleClasses}>
            {intl.formatMessage({
              id: "projects.b2-connect.solution-description.title",
            })}
          </h2>
          <p className={componentStyles.solutionDescriptionText}>
            {intl.formatMessage({
              id: "projects.b2-connect.solution-description.paragraph-1",
            })}
          </p>
          <p className={componentStyles.solutionDescriptionText}>
            {intl.formatMessage({
              id: "projects.b2-connect.solution-description.paragraph-2",
            })}
          </p>
        </div>
        <img
          src={SolutionDescriptoionImg}
          className={componentStyles.solutionDescriptionImage}
          alt={intl.formatMessage({
            id: "projects.b2-connect.solution-description.image-alt",
          })}
          width="352"
          height="363"
        />
      </section>
      <section className={technologyBackgroundClasses}>
        <div className={componentStyles.technologyBackgroundTextBlock}>
          <h2 className={technologyBackgroundTitleClasses}>
            {intl.formatMessage({
              id: "projects.b2-connect.technology-background.title",
            })}
          </h2>
          <p className={componentStyles.technologyBackgroundText}>
            {intl.formatMessage({
              id: "projects.b2-connect.technology-background.paragraph-1",
            }, { b: strongTextFormatter })}
          </p>
          <p className={componentStyles.technologyBackgroundText}>
            {intl.formatMessage({
              id: "projects.b2-connect.technology-background.paragraph-2",
            }, { b: strongTextFormatter })}
          </p>
        </div>
        <img
          src={TechnologyBackgroundImg}
          className={componentStyles.technologyBackgroundImage}
          alt={intl.formatMessage({
            id: "projects.b2-connect.technology-background.image-alt",
          })}
          width="275"
          height="480"
        />
      </section>
      <section className={resultsClasses}>
        <h2 className={resultsTitleClasses}>
          {intl.formatMessage({
            id: "projects.b2-connect.results.title",
          })}
        </h2>
        <p className={componentStyles.resultsText}>
          {intl.formatMessage({
            id: "projects.b2-connect.results.paragraph-1",
          })}
        </p>
        <ul className={resultsListClasses}>
          {resultsItems.map((translatePath, index) => (
            <li key={translatePath}
              className={componentStyles.resultsCubeListItem}
              style={{ gridArea: `item${(index + 1)}` }}>
              {intl.formatMessage({
                id: translatePath,
              })}
            </li>
          ))}
        </ul>
      </section>
      <section className={blockPreview}>
        <h2 className={projectsTitleClasses}>
          {intl.formatMessage({ id: "index.projects.next-project" })}
        </h2>
        {projects
          .filter(project => project.link !== projectUrl)
          .slice(projectIndex, projectIndex + 1)
          .map(({ title, color, link, textColor }) => {
            const thumbnail = getBigThumbnailByLink(link, projectsThumbnails)
            return (
              <SmallProjectCard
                key={title}
                title={title}
                thumbnail={thumbnail}
                link={link}
                color={color}
                textColor={textColor}
                buttonColor="orange"
              />
            )
          })}
      </section>
      <LearnMoreSection />
    </Layout>
  )
}
export default B2Connect
